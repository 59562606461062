import $ from 'jquery'

const $doc = $(document)

function toggleTabItem() {
  console.log('ajfhafk')
  const targetContent = $(this).data('target')
  let el = $(this);
  console.log(targetContent)

  el.parent().parent().find('.js-tab-item').removeClass('active').attr('aria-selected', 'false').attr('tabindex', '-1')
  el.parent().parent().find('.js-tab-content').removeClass('active').attr('hidden', 'true')

  el.addClass('active').attr('aria-selected', 'true').removeAttr('tabindex')
  $(`#${targetContent}`).addClass('active').removeAttr('hidden')
}


$(window).load(function(){

  const hash = window.location.hash;
  if (hash) {
    $(document).scrollTop(0);
    const tab = hash.substring(hash.indexOf("#") + 1);
    if($(window).width() < 700) {
      const anchor = $(`.js-tab-content[data-anchor="${tab}"]`);
      $('html, body').scrollTop(anchor.offset().top);
    } else {
      $('html, body').scrollTop($("#for-partners").offset().top);
      const button = $(`[data-anchor="${tab}"]`);
      const targetContent = button.data('target')
      button.parent().parent().find('.js-tab-item').removeClass('active').attr('aria-selected', 'false').attr('tabindex', '-1')
      button.parent().parent().find('.js-tab-content').removeClass('active').attr('hidden', 'true')
      button.addClass('active').attr('aria-selected', 'true').removeAttr('tabindex')
      $(`#${targetContent}`).addClass('active').removeAttr('hidden')
    }
  }

  $("li[class*='scroll-to-']").click(function(event) {
    if($(window).width() < 700) {
      const tab = $(this).attr('class').split(' ')[0].split("scroll-to-").pop();
      const anchor = $(`.js-tab-content[data-anchor="${tab}"]`);
      $('html, body').scrollTop(anchor.offset().top);
    } else {
      const tab = $(this).attr('class').split(' ')[0].split("scroll-to-").pop();
      const url = "partners/#"+tab;
      if(window.location.href.indexOf("partners") > -1) {
        window.location.hash = "#"+tab
        window.location.reload()
      }
    }

  });


  var winWidth = $(window).width();
  if(winWidth >= 700){
    $doc.on('click', '.js-tab-item', toggleTabItem )
    $( '.js-tabs' ).each(function() {
      var index = 0;
      var $tabs = $(this).find('.js-tab-item');

      $tabs.bind(
      {
        keydown: function(ev){
          var LEFT_ARROW = 37;
          var UP_ARROW = 38;
          var RIGHT_ARROW = 39;
          var DOWN_ARROW = 40;

          var k = ev.which || ev.keyCode;

          if (k >= LEFT_ARROW && k <= DOWN_ARROW){

            if (k == LEFT_ARROW || k == UP_ARROW){
              if (index > 0) {
                index--;
              }
              else {
                index = $tabs.length - 1;
              }
            }

            else if (k == RIGHT_ARROW || k == DOWN_ARROW){
              if (index < ($tabs.length - 1)){
                index++;
              }
              else {
                index = 0;
              }
            }

            $($tabs.get(index)).click();
            ev.preventDefault();
          }
        },

        click: function(ev){
          index = $.inArray(this, $tabs.get());
          setFocus();
          ev.preventDefault();
        }
      });

      var setFocus = function(){

        $tabs.attr(
        {
          tabindex: '-1',
          'aria-selected': 'false'
        }).removeClass('active');

        $(this).find('.js-tab-contet').removeClass('active').attr('hidden', 'true');

        $($tabs.get(index)).attr(
        {
          tabindex: '0',
          'aria-selected': 'true'
        }).addClass('active').focus();

        $($($tabs.get(index)).data('target')).addClass('active').removeAttr('hidden');
      };
    });
  }
});
$(window).resize(function(){
  var winWidth = $(window).width();
  if(winWidth >= 700){
    $doc.on('click', '.js-tab-item', toggleTabItem )
    $( '.js-tabs' ).each(function() {
      var index = 0;
      var $tabs = $(this).find('.js-tab-item');

      $tabs.bind(
      {
        keydown: function(ev){
          var LEFT_ARROW = 37;
          var UP_ARROW = 38;
          var RIGHT_ARROW = 39;
          var DOWN_ARROW = 40;

          var k = ev.which || ev.keyCode;

          if (k >= LEFT_ARROW && k <= DOWN_ARROW){

            if (k == LEFT_ARROW || k == UP_ARROW){
              if (index > 0) {
                index--;
              }
              else {
                index = $tabs.length - 1;
              }
            }

            else if (k == RIGHT_ARROW || k == DOWN_ARROW){
              if (index < ($tabs.length - 1)){
                index++;
              }
              else {
                index = 0;
              }
            }

            $($tabs.get(index)).click();
            ev.preventDefault();
          }
        },

        click: function(ev){
          index = $.inArray(this, $tabs.get());
          setFocus();
          ev.preventDefault();
        }
      });

      var setFocus = function(){

        $tabs.attr(
        {
          tabindex: '-1',
          'aria-selected': 'false'
        }).removeClass('active');

        $(this).find('.js-tab-content').removeClass('active').attr('hidden', 'true');

        $($tabs.get(index)).attr(
        {
          tabindex: '0',
          'aria-selected': 'true'
        }).addClass('active').focus();

        $($($tabs.get(index)).data('target')).addClass('active').removeAttr('hidden');
      };
    });
  }
});
