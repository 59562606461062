import $ from 'jquery'

$(document).ready( zipCodeBooking )

function zipCodeBooking(){
  $('.js-zip-code').on("change keyup input", function () {
    let input = $(this);
    let form = input.parent();
    let val = input.val();
    
    this.value = this.value.replace(/[^0-9\.]/g, '');
    form.attr('action', 'https://book.geodehealth.com/welcome?zip='+val);
  });
}
