import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

$doc.ready( ()=>{
  $('.js-open-modal').magnificPopup({
    type: 'inline',
    midClick: true,
    mainClass: 'mfp-fade',
    closeMarkup: '<button class="mfp-close"><span class="u-sr-only">Close Modal</span><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="19.5" stroke="black"/><path d="M11.5146 11.5156L28.4852 28.4862" stroke="black"/><path d="M28.4854 11.5156L11.5148 28.4862" stroke="black"/></svg></button>'
  });
})
