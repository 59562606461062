import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

$doc.ready( ()=>{
  $('.js-open-modal').magnificPopup({
    type: 'inline',
    midClick: true,
    mainClass: 'mfp-fade',
    closeMarkup: '<button class="mfp-close"><span class="u-sr-only">Close Modal</span><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.8337 2.7515L23.2487 0.166504L13.0003 10.4148L2.75199 0.166504L0.166992 2.7515L10.4153 12.9998L0.166992 23.2482L2.75199 25.8332L13.0003 15.5848L23.2487 25.8332L25.8337 23.2482L15.5853 12.9998L25.8337 2.7515Z" fill="#C2D1F9"/></svg></button>'
  });
})
