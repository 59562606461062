import $ from 'jquery'

$(document).ready(initLocations)
if (window.acf) {
    window.acf.addAction('render_block_preview/type=locations', initLocations)
}

function initLocations() {
  // variables
  const stateRadio = document.querySelectorAll('input[name="state"]');
  const cityRadio = document.querySelectorAll('input[name="city"]');
  // const practiceRadio = document.querySelectorAll('input[name="practice"]');
  const radioGroupDiv = document.querySelectorAll('.provider-fieldset__option');
  const providerFieldset = document.querySelectorAll('.provider-fieldset');
  const cityGroupDiv = document.querySelectorAll('.provider-fieldset--city .provider-fieldset__option');
  // const practiceGroupDiv = document.querySelectorAll('.provider-fieldset--practice .provider-fieldset__option');
  const buttons = document.querySelectorAll('.provider-search__attr > button');
  const goLink = document.querySelector('.provider-search__link');

  // fire function on change/click for each radio set/button
  for(const radioButton of stateRadio){
    radioButton.addEventListener('change', styleSelected);
    radioButton.addEventListener('click', closeLists);
    radioButton.addEventListener('click', filterCities);
  }
  for(const radioButton of cityRadio){
    radioButton.addEventListener('change', styleSelected);
    radioButton.addEventListener('click', closeLists);
    // radioButton.addEventListener('click', filterPractices);
    radioButton.addEventListener('click', setLinkValue);
  }
  // for(const radioButton of practiceRadio){
  //   radioButton.addEventListener('change', styleSelected);
  //   radioButton.addEventListener('click', closeLists);
  // }
  for(const button of buttons){
    button.addEventListener('click', showList);
  }

  // style selection function
  function styleSelected() {
    let newButtonText = this.parentElement.children[1].innerHTML;

    if (this.checked) {
      for(const item of radioGroupDiv){
        // remove all checked classes
        item.classList.remove('provider-fieldset__option--checked');
      }
      // add checked class
      this.parentElement.classList.add('provider-fieldset__option--checked');

    }
    // update button text
    this.parentElement.parentElement.parentElement.children[0].children[1].innerHTML = newButtonText;
  }

  // close all radio lists
  function closeLists() {
    for(const list of providerFieldset) {
      list.classList.remove('provider-fieldset--open');
    }
    // deactivate all buttons
    for(const button of buttons) {
      button.classList.remove('provider-search__attr__btn--open');
    }
  }

  // filter out cities that don't match the selected state
  function filterCities() {
    let selectedState = this.id.replace('state','');
    let matchingCities = document.querySelectorAll(".provider-fieldset--city .childof" + selectedState);
    const cityButton = document.querySelector(".provider-search__attr__city-btn");

    if (selectedState != "") {
      // enable city filter
      cityButton.disabled = false;
      // reset city button text
      cityButton.children[1].innerHTML = 'City'
      // reset go link
      goLink.setAttribute('href', '/locations/')

      // set them all as disabled and remove class checked
      for(const item of cityGroupDiv){
        item.children[0].disabled = true;
        item.classList.remove('provider-fieldset__option--checked');
      }

      // remove disabled for matching cities
      for(const city of matchingCities) {
        city.children[0].disabled = false;
      }
    }
  }

  // filter out practices that aren't in the selected city
  // function filterPractices() {
  //   let selectedCity = this.id.replace('city','');
  //   let matchingPractices = document.querySelectorAll(".provider-fieldset--practice .childof" + selectedCity);
  //   const practiceButton = document.querySelector(".provider-search__attr__practice-btn");

  //   if (selectedCity != "") {
  //     // enable practice filter
  //     practiceButton.disabled = false;

  //     // set them all as disabled
  //     for(const item of practiceGroupDiv){
  //       item.children[0].disabled = true;
  //     }

  //     // remove disabled for matching cities
  //     for(const practice of matchingPractices) {
  //       practice.children[0].disabled = false;
  //     }
  //   }
  // }

  // button functionality to hide/show radio lists
  function showList() {
    if ((this.parentElement.children[1].disabled == false) && (this.disabled == false)) {
      let active = false;
      //check if any list open
      for(const list of providerFieldset) {
        if(list.classList.contains('provider-fieldset--open')) {
          active = true;
        }
      }
      //check if any button active
      for(const button of buttons) {
        if(button.classList.contains('provider-search__attr__btn--open')) {
          active = true;
        }
      }
      if(active == true) {
        closeLists()
      }

      this.parentElement.children[1].classList.toggle('provider-fieldset--open');
      this.classList.toggle('provider-search__attr__btn--open');
    }
  }

  // set the "go" button's link value to selected city
  function setLinkValue() {
    for(const radioButton of cityRadio){
      if (radioButton.checked == true) {
        goLink.setAttribute('href', this.dataset.link)
        $('html, body').animate(
          {
            scrollTop: $('.locations').offset().top
          },
          400
        );
      }
    }
  }
}
