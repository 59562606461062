import $ from 'jquery'

const $doc = $(document)

function toggleAccordionItem() {
  let el = $(this);
  let parent = el.parent().parent();
  let content = el.parent().next();

  $('.js-accordion-item').not(parent).removeClass('active');
  $('.accordion__single-item-content').not(content).slideUp('fast');
  $('.js-accordion-item button').not(el).attr('aria-expanded', 'false');

  if ( parent.hasClass('active') ) {
    content.slideUp('fast');
    el.attr('aria-expanded', 'false');
    parent.removeClass('active');
  } else {
    parent.addClass('active')
    content.slideDown('fast');
    el.attr('aria-expanded', 'true');
  }

  setTimeout((function () {
    $('html').animate({
        scrollTop: el.offset().top - 100
      }, 200
    );
  }), 180);

}

$doc.on('click', '.js-accordion-item button', toggleAccordionItem )
