import $ from 'jquery'
import 'slick-carousel';

$(document).ready( initTestimonialsSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=testimonials', initTestimonialsSlider)
}

function initTestimonialsSlider(){
  const $sliders = $('.js-testimonials-slider:not(.slick-initialized)')
  if ($sliders.length) {
    $sliders.each(function () {
      let $el = $(this);
      $el.slick({
        arrows: true,
        dots: true,
        speed: 600,
        //fade: true,
        prevArrow: '<button type="button" class="slick-prev"><svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7428 20.6815C12.1059 20.2795 12.0816 19.6527 11.6886 19.2813L2.39617 10.5L11.6886 1.71869C12.0816 1.34735 12.1059 0.720455 11.7428 0.318491C11.3798 -0.0834732 10.767 -0.108295 10.374 0.263048L0.311353 9.77218C0.11287 9.95975 0 10.2236 0 10.5C0 10.7764 0.11287 11.0403 0.311353 11.2278L10.374 20.737C10.767 21.1083 11.3798 21.0835 11.7428 20.6815Z" fill="#433897"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.257156 20.6815C-0.105867 20.2795 -0.0816011 19.6527 0.311358 19.2813L9.60383 10.5L0.311358 1.71869C-0.0816011 1.34735 -0.105867 0.720455 0.257156 0.318491C0.620182 -0.0834732 1.23303 -0.108295 1.62599 0.263048L11.6886 9.77218C11.8871 9.95975 12 10.2236 12 10.5C12 10.7764 11.8871 11.0403 11.6886 11.2278L1.62599 20.737C1.23303 21.1083 0.620182 21.0835 0.257156 20.6815Z" fill="#433897"/></svg></button>'
      });
    });
  }
}
